<template>
  <!--  -->
  <div
    class="consulting"
    :class="{
      consulting1: typeTwo,
      consulting2: typeFour,
    }"
  >
    <navigation3 class="navigation3" :is-detail="false" />
    <div class="consulting_top">
      <div class="consulting_top_box dis_flex_start">
        <div
          v-for="(item, index) in ids"
          :key="index"
          class="consulting_top_box_item"
          :class="active == index ? 'active' : ''"
          @click="switchs(item.id, index)"
        >
          {{ item.name + "发证单位" }}
          <div :class="active == index ? 'triangle' : ''" />
        </div>
      </div>
    </div>
    <div class="consulting_content">
      <!-- 左侧导航 -->
      <div class="consulting_content_leftBox">
        <div
          v-for="(item, index) in exm"
          :key="index"
          class="consulting_content_leftBox_item"
          :class="index == isActive ? 'isActive' : ''"
          
          @click="jump(index)"
        >
          <div v-if="index == isActive" class="line" />
          {{ item.name }}
        </div>
      </div>
      <!-- 中间内容 -->
      <div class="consulting_content_middleBox" id="content" ref="anchorRef">
        <div class="middleBox_top">
          <div class="middleBox_title">{{ details.certificateName }}</div>
          <div class="middleBox_tip">
            <div class="tip_left">
              <div class="tip_item">
                <span>证书分类：</span
                ><span class="item_tip">{{ details.classifName }}</span>
              </div>
              <div class="tip_item">
                <span>招生区域：</span
                ><span class="item_tip">{{ details.areaName }}</span>
              </div>
              <!-- <div class="tip_item">学习周期<span class="item_tip">{{ details.areaName }}</span></div> -->
            </div>
            <div class="tip_right">
              <div class="tip_item1">
                发证单位：<span class="item_tip">{{
                  details.issueUnitName
                }}</span>
              </div>
              <div class="tip_item1">
                费用：<span
                  class="item_tip"
                  v-for="(itemss, indexss) in details.cost"
                  :key="indexss"
                >
                  {{ itemss.name }}
                  <span class="txt_red">{{ itemss.charge }}元</span>
                  <span v-if="indexss !== details.cost.length - 1"> +</span>
                </span>
              </div>
            </div>
          </div>
          <!-- 证书介绍 -->
          <div class="middleBox_introduce" id="title0">
            <div class="introduce_name">证书介绍</div>
            <div class="introduce_content" v-html="details.introduce"></div>
          </div>
        </div>
        <div class="middleBox_bottom">
          <!-- 报名条件 -->
          <div class="middleBox_condition" id="title1">
            <div class="condition_name">报名条件</div>
            <div class="condition_content">{{ details.bmCondition }}</div>
          </div>
          <!-- 报名资料 -->
          <div class="middleBox_condition" id="title2">
            <div class="condition_name">报名资料</div>
            <div class="condition_content">{{ details.bmMaterial }}</div>
          </div>
          <!-- 考试内容 -->
          <div class="middleBox_condition" id="title3">
            <div class="condition_name">考试内容</div>
            <div class="condition_exam">
              <el-table class="table" :data="details.examList" border>
                <el-table-column
                  prop="examName"
                  label="考试科目"
                  align="center"
                  width="309px"
                >
                  <template slot-scope="{ row }">
                    <div style="color: #3d6cc8">
                      {{ row.examName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="examDate"
                  label="考试日期"
                  align="center"
                  width="310px"
                >
                  <template slot-scope="{ row }">
                    <div>
                      {{ row.examDate }}
                      <br />
                      ({{ getWeekday(row.examDate) }})
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="考试时间" align="center" width="410px">
                  <template slot-scope="{ row }">
                    <div>
                      <span
                        v-if="
                          row.examStartTime.split(':')[0] == '08' ||
                          row.examStartTime.split(':')[0] == '09' ||
                          row.examStartTime.split(':')[0] == '10' ||
                          row.examStartTime.split(':')[0] == '11' ||
                          row.examStartTime.split(':')[0] == '12'
                        "
                        >上午</span
                      >
                      <span
                        v-if="
                          row.examStartTime.split(':')[0] == '13' ||
                          row.examStartTime.split(':')[0] == '14' ||
                          row.examStartTime.split(':')[0] == '15' ||
                          row.examStartTime.split(':')[0] == '16' ||
                          row.examStartTime.split(':')[0] == '17' ||
                          row.examStartTime.split(':')[0] == '18'
                        "
                        >下午</span
                      >
                      {{ row.examStartTime }}-{{ row.examEndTime }}
                      <span class="exam_time">
                        共{{
                          getTimeDiff(row.examStartTime, row.examEndTime)
                        }}分钟
                      </span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 准考证打印时间 -->
          <div class="middleBox_condition" id="title4">
            <div class="condition_name">准考证打印时间</div>
            <div class="condition_time">
              <div class="time">
                {{ details.printStartTime }} - {{ details.printEndTime }}
              </div>
              <div class="time_btn">
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  :content="details.printUrl"
                  placement="top"
                 
                > -->
                <div
                  class="print"
                  v-if="isEnable == 1"
                  @click="handlePrint(details.printUrl)"
                >
                  打印
                </div>
                <!-- </el-tooltip> -->
                <div class="print_btn" v-else-if="isEnable == 3">
                  <el-button class="btn" round type="primary"> 打印 </el-button>
                  <span class="not_time"> (时间未到，还不能打印哦) </span>
                </div>
                <div class="print_btn" v-else-if="isEnable == 2">
                  <el-button class="btn" round type="primary"> 打印 </el-button>
                  <span class="not_time"> (时间已过，不能打印哦) </span>
                </div>
              </div>
            </div>
          </div>
          <!-- 毕业条件 -->
          <div class="middleBox_condition" id="title5">
            <div class="condition_name">毕业条件</div>
            <div class="condition_content">{{ details.graduation }}</div>
          </div>
          <!-- 成绩查询 -->
          <div class="middleBox_condition" id="title6">
            <div class="condition_name">成绩查询</div>
            <div class="condition_grade" v-if="details.gradeUrl">
              <!-- <el-tooltip
                class="item"
                effect="dark"
                :content="details.gradeUrl"
                placement="top"
              > -->
              <div class="grade" @click="scoreInquiry(details.gradeUrl)">
                点击查询
              </div>
              <!-- </el-tooltip> -->
            </div>
            <div class="condition_grade" v-else>
              <div class="grade1">点击查询</div>
            </div>
          </div>
          <!-- 证书领取 -->
          <div class="middleBox_condition" id="title7">
            <div class="condition_name">证书领取</div>
            <div class="condition_get">{{ details.certificateReceive }}</div>
          </div>
        </div>
      </div>
      <!-- 右侧推荐 -->
      <!-- <div class="consulting_content_rightBox">
        <div class="consulting_content_rightBox_top">
          <div class="consulting_content_rightBox_title">课程推荐</div>
          <div class="consulting_content_rightBox_list">
            <div
              class="consulting_content_rightBox_list_item"
              v-for="(item, index) in 4"
              :key="index"
            >
              <div class="consulting_content_rightBox_list_item_title">
                一级建造师证-建设工程经济
              </div>
              <div class="consulting_content_rightBox_list_item_content">
                <div class="leftBox">
                  <span style="margin-right: 14px">直播</span
                  ><span>213课时</span>
                </div>
                <div class="rightBox">
                  <el-button class="rightBox_btn">查看</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="consulting_content_rightBox_bottom">
          <div class="consulting_content_rightBox_title">相关咨询</div>
        </div>
      </div> -->
    </div>
    <div id="title8" class="apply">
      <div class="apply_text">
        <div class="apply_text_text">{{ details.certificateName }}在线报名</div>
      </div>
      <div class="apply_centent">
        <el-form
          class="centent_form"
          :rules="rules"
          ref="form"
          :model="form"
          label-position="left"
        >
          <el-form-item label="姓名：" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入您的姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="请输入您的手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
            <el-input v-model="form.code" placeholder="请输入验证码">
              <span
                class="yCode"
                slot="suffix"
                v-if="!downTime"
                @click="codeClick"
                >获取验证码</span
              >
              <el-statistic
                v-else
                slot="suffix"
                :value="countDown"
                time-indices
                format="ss"
                @finish="timeFinish"
              >
                <template slot="suffix">s</template>
              </el-statistic>
            </el-input>
          </el-form-item>
          <el-form-item label="意向证书：" v-if="details.certificateName">
            <el-tooltip
              class="item"
              effect="dark"
              :content="details.certificateName"
              placement="top"
            >
              <el-input
                class="certificateName_input"
                v-model="details.certificateName"
                :placeholder="details.certificateName"
                disabled
              ></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="意向证书：" v-else>
              <el-input
                class="certificateName_input"
                v-model="details.certificateName"
                :placeholder="details.certificateName"
                disabled
              ></el-input>
          </el-form-item>
        </el-form>
        <div class="submit_btn" @click="submitClick">
          <el-button class="btn">立即提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navigation3 from "./components/navigation3.vue";
import {
  getDetails,
  getOrgCertificateList,
  addEnrollUserConsultation,
} from "@/api/home";
import { loginSmsCode } from "@/api/login";
import { getDomain } from "@/api/cookies";
export default {
  components: { navigation3 },

  data() {
    return {
      // 倒计时
      countDown: Date.now() + 1000 * 60,
      downTime: false,
      isEnable: null,
      id: "",
      ids: [],
      isActive: "",
      active: "",
      classifId: "",
      isDetail: false,
      zigeList: [],
      certificateName: "",
      exm: [
        { name: "证书信息" },
        { name: "证书介绍" },
        { name: "报名条件" },
        { name: "报名资料" },
        { name: "考试科目" },
        { name: "准考证打印" },
        { name: "毕业条件" },
        { name: "成绩查询" },
        { name: "在线报名" },
      ],
      details: {},
      printTime: {
        printStartTime: null,
        printEndTime: null,
      },
      form: {},
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入合法手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      typeOne: false,
      typeTwo: false,
      typeThree: false,
      typeFour: false,
      top: "",
    };
  },
  mounted() {
    setInterval(() => {
      const now = new Date();
      const printStartTime = new Date(this.printTime.printStartTime);
      const printEndTime = new Date(this.printTime.printEndTime);
      if (now >= printStartTime && now <= printEndTime) {
        this.isEnable = 1;
      } else if (now > printEndTime) {
        this.isEnable = 2;
      } else {
        this.isEnable = 3;
      }
    }, 1000);
    this.$nextTick(function () {
      window.addEventListener("scroll", this.scroll); // 监听页面滚动
    });
  },
  async created() {
    this.certificateName = (await this.$route.query.certificateName)
      ? this.$route.query.certificateName
      : 0;
    this.classifId = (await this.$route.query.classifId)
      ? this.$route.query.classifId
      : 0;
    this.getOldList();
    // await this.getDetails()
    const res = getDomain();
    if (res.entrantsType === 2) {
      this.typeOne = true;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 3) {
      this.typeOne = false;
      this.typeTwo = true;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 5) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = true;
      this.typeFour = false;
    } else if (res.entrantsType === 1) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = true;
    }
  },
  methods: {
    scroll(e) {
      const navList = document.querySelectorAll(".middleBox_condition");
      const offsetTopArr = [];
      navList.forEach((item) => {
        offsetTopArr.push(item.offsetTop);
      });
      const scrollTop = document.documentElement.scrollTop;
      offsetTopArr.forEach((item, index) => {
        if (scrollTop >= item) {
          this.isActive = index +2;
        } else if (scrollTop == 300) {
          this.isActive = 1;
        } else if (scrollTop == 0) {
          this.isActive = 0;
        } 
      });
    },
    // 获取验证码
    async codeClick() {
      
      this.countDown = Date.now() + 1000 * 60;
      if (!this.form.phone) {
        this.$message.error("请先输入手机号");
        return;
      } else {
        const res = await loginSmsCode(this.form.phone, "welcomePotential");
        if (res.code === 0) {
          this.$message.success(res.msg);
          this.downTime = true;
        } else if (res.code === 500) {
          this.$message.error("请勿频繁获取！");
        }
      }
    },
    // 倒计时结束
    timeFinish(val) {
      if (val) return (this.downTime = false);
    },
    // 立即提交
    submitClick() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await addEnrollUserConsultation({
            studentName: this.form.name,
            mobile: this.form.phone,
            mobileToken: this.form.code,
            intentionMajor: this.details.certificateName,
            projectType: "6",
            projectId:this.details.id
          });
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.form = {}
            this.downTime = false
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return;
        }
      });
    },
    // 打印
    handlePrint(val) {
      if (val) {
        window.open(val, "_blank");
      }
    },
    // 成绩查询
    scoreInquiry(val) {
      window.open(val, "_blank");
    },

    // 计算日期是周几
    getWeekday(dateStr) {
      const weekdays = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      const date = new Date(dateStr);
      const weekday = weekdays[date.getDay()];
      return weekday;
    },
    //计算考试时长
    getTimeDiff(start, end) {
      const startArr = start.split(":").map((v) => +v);
      const endArr = end.split(":").map((v) => +v);
      return (endArr[0] - startArr[0]) * 60 + endArr[1] - startArr[1];
    },

    async getOldList() {
      const params = {
        pageSize: 10,
        pageNum: 1,
        classifId: this.classifId,
      };
      const res = await getOrgCertificateList(params);
      const list = res.rows;
      this.zigeList = list.filter(
        (e) => this.certificateName == e.certificateName
      );
      this.ids = this.zigeList.map((e) =>
        Object.assign({}, { name: e.unitName, id: e.id })
      );
      this.switchs(this.ids[0]?.id, 0);
    },
    /* 项目修改 */
    async getDetails() {
      const res = await getDetails(this.id);
      if (res.code == 0) {
        res.data.cost = JSON.parse(res.data.cost);
        this.details = res.data;
        const { printStartTime, printEndTime } = res.data;
        this.printTime.printStartTime = new Date(printStartTime);
        this.printTime.printEndTime = new Date(printEndTime);
      }
    },

    switchs(val, index) {
      this.id = val;
      this.active = index;
      this.getDetails();
    },
    query(val) {
      window.open(this.details.gradeUrl, "_blank");
    },
    print(val) {
      window.open(this.details.printUrl, "_blank");
    },
    jump(val) {
      this.isActive = val;
      if (!val) {
        return;
      }
      if(val === 8){
        document.documentElement.scrollTop =
        document.querySelector(`#title${val}`).offsetTop + 350;
        window.removeEventListener("scroll",this.scroll)
        setInterval(() => {
          window.addEventListener("scroll", this.scroll); // 监听页面滚动
        }, 500);
      } else {
        document.documentElement.scrollTop =
          document.querySelector(`#title${val}`).offsetTop - 150;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.consulting {
  .navigation3 {
    z-index: 999;
    color: #000 !important;
    background-color: #fff !important;

    /deep/ .menu-box {
      color: rgba(51, 51, 51, 1);
    }
  }

  .consulting_top {
    width: 1200px;
    margin: 0 auto;

    .consulting_top_box {
      padding-top: 20px;
      padding-bottom: 23px;

      .consulting_top_box_item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 180px;
        padding: 0 10px;
        margin-right: 30px;
        height: 36px;
        background: #d5e3ff;
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;

        .triangle {
          position: absolute;
          width: 20px;
          height: 7px;
          border-left: 10px solid transparent;
          /* 调整大小以适应您的需求 */
          border-right: 10px solid transparent;
          /* 调整大小以适应您的需求 */
          border-top: 6px solid #3d6cc8;
          /* 调整大小以适应您的需求 */
          left: 50%;
          transform: translateX(-50%);
          bottom: -7px;
          /* 调整距离以适应您的需求 */
        }
      }

      .active {
        background: #3d6cc8;
        color: #ffffff;
      }
    }
  }
  .consulting_content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: start;
    .consulting_content_leftBox {
      width: 110px;
      height: 475px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      position: sticky;
      // position: fixed;
      top: 0px;
      // left: 375px;

      .consulting_content_leftBox_item {
        position: relative;
        width: 110px;
        height: 50px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        font-size: 14px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #464646;
        // padding: 14px 27px;
        display: flex;
        justify-content: center;
        align-items: center;

        .line {
          position: absolute;
          left: 0px;
          top: 0;
          width: 4px;
          height: 50px;
          background: #df3c2f;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
      }
      .isActive {
        background: #ffeeed;
        color: #df3c2f;
      }
    }

    .consulting_content_middleBox {
      width: 1080px;
      margin-left: 20px;

      .middleBox_top {
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;

        .middleBox_title {
          font-size: 28px;
          font-family: PingFangSC-Semibold-, PingFangSC-Semibold;
          font-weight: normal;
          color: #333333;
          letter-spacing: 2px;
          text-align: center;
          padding-top: 32px;
        }

        .middleBox_tip {
          width: 900px;
          margin: 0 auto;
          // background: linear-gradient(to bottom left, #e8e3dd, #fffbf7 100%);
          background: url("~@/assets/img/zige/bg.png") no-repeat;
          background-size: 100%;
          border-radius: 6px;
          opacity: 1;
          padding: 28px 94px 28px 74px;
          margin-top: 22px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .tip_right {
            text-align: right;
          }
          .tip_item,
          .tip_item1 {
            margin-bottom: 20px;
            font-size: 16px;
            font-family: PingFangSC-Medium-, PingFangSC-Medium;
            font-weight: normal;
            color: #333333;
            letter-spacing: 1px;
            width: 350px;
            // display: flex;
            .item_tip {
              font-size: 14px;
              font-family: PingFangSC-Regular-, PingFangSC-Regular;
              font-weight: normal;
              color: #555555;
              letter-spacing: 0;

              .txt_red {
                color: #bc3535;
              }
            }
          }
          .tip_item1 {
            width: auto;
            text-align: left;
          }
          .tip_item:nth-last-child(1) {
            margin-bottom: 0;
          }
          .tip_item1:nth-last-child(1) {
            margin-bottom: 0;
          }
        }

        .middleBox_introduce {
          width: 1030px;
          margin: 27px auto 0;
          padding-bottom: 50px;

          .introduce_name {
            font-size: 18px;
            font-family: PingFangSC-Medium-, PingFangSC-Medium;
            font-weight: normal;
            color: #333333;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
          }

          .introduce_name::before {
            content: "";
            display: block;
            width: 8px;
            height: 26px;
            background: linear-gradient(191deg, #88c8ff 0%, #1061ff 100%);
            border-radius: 1px 1px 1px 1px;
            opacity: 1;
            margin-right: 10px;
          }

          .introduce_content {
            width: 990px;
            margin: 0 auto;
            // padding: 0 20px;
            margin-top: 16px;
            font-size: 16px;
            font-family: PingFangSC-Regular-, PingFangSC-Regular;
            font-weight: normal;
            color: #333333;
            letter-spacing: 1px;
            line-height: 25px;
          }
        }
      }

      .middleBox_bottom {
        margin-top: 24px;
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;

        .middleBox_condition {
          width: 1030px;
          margin: 0 auto;
          padding-top: 30px;

          .condition_name {
            font-size: 18px;
            font-family: PingFangSC-Medium-, PingFangSC-Medium;
            font-weight: normal;
            color: #333333;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
          }

          .condition_name::before {
            content: "";
            display: block;
            width: 8px;
            height: 26px;
            background: linear-gradient(191deg, #88c8ff 0%, #1061ff 100%);
            border-radius: 1px 1px 1px 1px;
            opacity: 1;
            margin-right: 10px;
          }

          .condition_exam {
            margin-top: 20px;

            .table {
              border-radius: 6px;
              .exam_time {
                margin-left: 42px;
              }
            }
          }

          .condition_content {
            width: 990px;
            margin: 0 auto;
            padding: 18px 22px;
            margin-top: 16px;
            font-size: 16px;
            font-family: PingFangSC-Regular-, PingFangSC-Regular;
            font-weight: normal;
            color: #333333;
            line-height: 25px;
            background: #f8f8f8;
            border-radius: 6px 6px 6px 6px;
          }

          .condition_time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 990px;
            margin: 20px auto 0;

            .time {
              padding: 15px 280px 15px 18px;
              background: #f8f8f8;
              border-radius: 6px 6px 6px 6px;
              opacity: 1;
              font-size: 16px;
              font-family: PingFangSC-Medium-, PingFangSC-Medium;
              font-weight: normal;
              color: #3d6cc8;
            }

            .time_btn {
              .print {
                width: 88px;
                height: 36px;
                background: linear-gradient(196deg, #88c8ff 0%, #1061ff 100%);
                border-radius: 20px 20px 20px 20px;
                opacity: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: PingFangSC-Regular-, PingFangSC-Regular;
                font-weight: normal;
                color: #ffffff;
                cursor: pointer;
              }

              .print_btn {
                .btn {
                  width: 88px;
                  height: 36px;
                  background: linear-gradient(180deg, #d7d7d7 0%, #8b8b8b 100%);
                  border-radius: 20px 20px 20px 20px;
                  opacity: 1;
                  border: none;
                }

                .not_time {
                  font-size: 14px;
                  font-family: PingFangSC-Regular-, PingFangSC-Regular;
                  font-weight: normal;
                  color: #666666;
                  margin-left: 20px;
                }
              }
            }
          }

          .condition_grade {
            margin-top: 20px;
            margin-left: 61px;
            .grade {
              width: 95px;
              height: 36px;
              background: linear-gradient(196deg, #88c8ff 0%, #1061ff 100%);
              border-radius: 20px 20px 20px 20px;
              opacity: 1;
              font-size: 16px;
              font-family: PingFangSC-Regular-, PingFangSC-Regular;
              font-weight: normal;
              color: #ffffff;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .grade1 {
              width: 95px;
              height: 36px;
              background: linear-gradient(180deg, #d7d7d7 0%, #8b8b8b 100%);
              border-radius: 20px 20px 20px 20px;
              opacity: 1;
              font-size: 16px;
              font-family: PingFangSC-Regular-, PingFangSC-Regular;
              font-weight: normal;
              color: #ffffff;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .condition_get {
            width: 990px;
            margin: 0 auto;
            margin-top: 20px;
            padding: 15px 200px 15px 18px;
            background: #f8f8f8;
            border-radius: 6px 6px 6px 6px;
            opacity: 1;
            font-size: 16px;
            font-family: PingFangSC-Medium-, PingFangSC-Medium;
            font-weight: normal;
            color: #3d6cc8;
          }
        }

        .middleBox_condition:nth-last-child(1) {
          padding-bottom: 40px;
        }
      }
    }

    .consulting_content_rightBox {
      width: 236px;

      .consulting_content_rightBox_top {
        width: 236px;
        height: 396px;
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        overflow: hidden;
        margin-bottom: 67px;
        .consulting_content_rightBox_title {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #3d6cc8;
          font-size: 18px;
          font-family: PingFangSC-Semibold-, PingFangSC-Semibold;
          font-weight: normal;
          color: #ffffff;
        }

        .consulting_content_rightBox_list {
          .consulting_content_rightBox_list_item {
            height: 89px;

            .consulting_content_rightBox_list_item_title {
              height: 40px;
              padding: 19px 0 0 15px;
              font-size: 14px;
              font-family: PingFangSC-Medium-, PingFangSC-Medium;
              font-weight: normal;
              color: #434343;
            }
            .consulting_content_rightBox_list_item_content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-left: 18px;
              padding-right: 13px;
                .rightBox_btn {
                  display: flex;
                  align-items: center;
                  width: 60px;
                  height: 24px;
                  border-radius: 14px 14px 14px 14px;
                  opacity: 1;
                  border: 1px solid #3d6cc8;
                }
              }
              .leftBox {
                padding-top: 17px;
                // width: 24px;
                height: 34px;
                font-size: 12px;
                font-family: PingFangSC-Regular-, PingFangSC-Regular;
                font-weight: normal;
                color: #777777;
              }
            }
          }
        }
      }
      .consulting_content_rightBox_bottom {
        width: 236px;
        height: 379px;
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        overflow: hidden;
        .consulting_content_rightBox_title {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #3d6cc8;
          font-size: 18px;
          font-family: PingFangSC-Semibold-, PingFangSC-Semibold;
          font-weight: normal;
          color: #ffffff;
        }
      }
    }
  }

  .apply {
    width: 100%;
    height: 330px;
    margin: 30px 0 0px 0;
    background: url("~@/assets/img/zige/sign_up_bg.png") no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .apply_text {
      display: flex;
      margin: 20px 0 20px 0;
      margin-left: 10px;
      align-items: center;

      .apply_text_text {
        font-size: 22px;
        font-family: PingFangSC-Semibold-, PingFangSC-Semibold;
        font-weight: normal;
        color: #df3c2f;
        letter-spacing: 1px;
      }
    }

    .apply_centent {
      width: 1200px;
      height: 220px;
      background: url("../../../assets/img/zige/sign_bg.png") no-repeat;
      background-size: 100%;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;

      .centent_form {
        margin-top: 57px;
        display: flex;
        justify-content: center;

        /deep/ .el-form-item:nth-last-child(1) {
          margin-right: 0;
        }

        /deep/ .el-form-item {
          display: flex;
          justify-content: center;
          margin-right: 40px;

          .el-form-item__label {
            font-size: 18px;
            font-family: PingFangSC-Medium-, PingFangSC-Medium;
            font-weight: normal;
            color: #444444;
            letter-spacing: 1px;
            padding: 0;
          }

          .el-form-item__label::before {
            content: "";
            margin-right: 0;
          }

          .el-form-item__content {
            width: 170px;
            color: #848484 !important;
            // margin-left: 0px !important;
            .el-input {
              width: 100%;
              color: #848484 !important;
              .el-input__inner {
                height: 30px !important;
                border-radius: 30px;
                background-color: transparent;
                border-color: #a42222;
                font-size: 12px;
                color: #848484 !important;
                width: 170px !important;
                overflow: hidden !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
              }

              .yCode {
                font-size: 12px;
                font-family: PingFangSC-Regular-, PingFangSC-Regular;
                font-weight: normal;
                color: #a42222;
                letter-spacing: 1px;
                cursor: pointer;
              }

              .el-statistic {
                margin: 13px 5px 0 0;
              }

              .number {
                font-size: 12px;
                font-family: PingFangSC-Regular-, PingFangSC-Regular;
                font-weight: normal;
                color: #333333;
                letter-spacing: 1px;
              }
            }
            ::placeholder{
              color: #848484 !important;
            }
            // height: 30px;
            // border-radius: 30px;
          }
        }
      }

      .submit_btn {
        margin-top: 24px;
        width: 200px;
        height: 46px;

        .btn {
          width: 100%;
          height: 100%;
          background: #a42222;
          border-radius: 25px 25px 25px 25px;
          font-size: 16px;
          font-family: PingFangSC-Semibold-, PingFangSC-Semibold;
          font-weight: normal;
          color: #ffffff;
          letter-spacing: 1px;
        }
      }
    }
  }
.consulting1 {
  padding-bottom: 170px;
}
.consulting2 {
  padding-bottom: 440px;
}
</style>
